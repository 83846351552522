export default {
  types: {
    definitions: 1,
    persons: 2,
    phrases: 3,
    documents: 4,
    documentItems: 5,
    documentItemTranslations: 6,
    documentTranslations: 7,
    references: 8,
    terms: 9
  },
  purpose: {
    author: 1,
    grouping: 2,
    inlinebody: 3,
    link: 4
  },
  typeDetailed: {
    Definitions: {
      id: 1,
      icon: 'mdi mdi-book-alphabet'
    },
    Persons: {
      id: 2,
      icon: 'mdi mdi-account-supervisor-circle'
    },
    Phrases: {
      id: 3,
      icon: 'mdi mdi-format-align-left'
    },
    Documents: {
      id: 4,
      icon: 'mdi mdi-folder-multiple-outline'
    },
    DocumentItems: {
      id: 5,
      icon: 'mdi mdi-folder-multiple-outline'
    },
    DocumentItemTranslations: {
      id: 6,
      icon: 'mdi mdi-folder-multiple-outline'
    },
    DocumentTranslations: {
      id: 7,
      icon: 'mdi mdi-folder-multiple-outline'
    }
  },
  getConnectionTypeById: function (id) {
    for (const i in this.typeDetailed) {
      if (this.typeDetailed[i].id === id) {
        return this.typeDetailed[i]
      }
    }

    return null
  },
  getConnectionTypeNameById: function (id) {
    for (const i in this.typeDetailed) {
      if (this.typeDetailed[i].id === id) {
        return i
      }
    }

    return null
  }
}