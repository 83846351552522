import Vue from 'vue'
import Router from 'vue-router'
import { Role } from '@/helpers'
import store from '@/store'
import configs from '@/configs'

Vue.use(Router)

export const routes = [
  {
    path: '/',
    redirect: { name: 'dashboard' }
  },
  {
    path: '/auth',
    name: 'auth_layout',
    component: () => import( /* webpackChunkName: "auth-layout" */ '@/layouts/Auth/DefaultLayout.vue' ),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import( /* webpackChunkName: "auth-login" */ '@/pages/Auth/LoginPage.vue' )
      },
      {
        path: 'register',
        name: 'register',
        component: () => import( /* webpackChunkName: "auth-register" */ '@/pages/Auth/RegisterPage.vue' )
      }
    ]
  },
  {
    path: '/app',
    name: 'app_layout',
    component: () => import( /* webpackChunkName: "app-layout" */ '@/layouts/App/DefaultLayout.vue' ),
    children: [
      {
        path: 'account',
        name: 'account_layout',
        component: () => import( /* webpackChunkName: "app-account" */ '@/layouts/App/AccountLayout.vue' ),
        children: [
          {
            path: 'profile',
            name: 'account_profile',
            component: () => import(/* webpackChunkName: "app-account" */ '@/pages/Profile/ProfilePage.vue')
          }
        ]
      },
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import( /* webpackChunkName: "app-dashboard" */ '@/pages/App/DashboardPage.vue' ),
        meta: { icon: 'mdi-home-circle', authorize: [] /*[Role.Admin]*/ }
      },
      {
        name: 'documents_landing',
        path: 'documents',
        component: () => import( /* webpackChunkName: "app-dashboard" */ '@/pages/Document/DocumentsLanding.vue' ),
        meta: { icon: 'mdi-file-document', authorize: [] /*[Role.Admin]*/ }
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import( /* webpackChunkName: "app-settings" */ '@/pages/App/SettingsPage.vue' )
      },
      {
        name: 'document_add',
        path: 'document/add',
        component: () => import(/* webpackChunkName: "app-document" */ '@/components/documents/forms/DocumentForm.vue')
      },
      {
        path: 'document/:documentid',
        component: () => import( /* webpackChunkName: "app-document" */ '@/layouts/App/DocumentLayout.vue' ),
        children: [
          {
            name: 'document_home',
            path: 'home',
            component: () => import(/* webpackChunkName: "app-document" */ '@/pages/Document/DocumentHome.vue')
          },
          {
            name: 'document_edit',
            path: 'edit',
            component: () => import(/* webpackChunkName: "app-document" */ '@/pages/Document/DocumentEditorPage.vue'),
            props: (route) => ({ documentId: Number(route.params.documentid) })
          },
          {
            name: 'document_settings',
            path: 'settings',
            component: () => import(/* webpackChunkName: "app-document-settings" */ '@/pages/Document/DocumentSettingPage.vue')
          },
          {
            name: 'document_translations',
            path: 'translations',
            component: () => import(/* webpackChunkName: "app-document-translations" */ '@/pages/Document/DocumentTranslationPage.vue')
          },
          {
            name: 'document_translations_edit',
            path: 'translations/:documenttranslationid/edit',
            component: () => import(/* webpackChunkName: "app-document-translations" */ '@/components/documents/forms/DocumentTranslationForm.vue'),
            props: (route) => ({ documentTranslationId: Number(route.params.documenttranslationid) })
          },
          {
            name: 'document_translations_add',
            path: 'translations/add',
            component: () => import(/* webpackChunkName: "app-document-translations" */ '@/components/documents/forms/DocumentTranslationForm.vue')
          },
          {
            name: 'documentitem_home',
            path: 'documentitem',
            component: () => import( /* webpackChunkName: "app-documentitem" */ '@/layouts/App/DocumentItemLayout.vue' ),
            children: [
              {
                name: 'documentitem_add',
                path: 'add',
                component: () => import(/* webpackChunkName: "app-documentitem" */ '@/components/documentItems/forms/DocumentItemForm.vue')
              },
              {
                name: 'documentitem',
                path: ':documentitemid',
                component: () => import(/* webpackChunkName: "app-documentitem" */ '@/pages/DocumentItem/DocumentItemPage.vue')
              },
              {
                name: 'documentitem_edit',
                path: ':documentitemid/edit',
                component: () => import(/* webpackChunkName: "app-documentitem" */ '@/pages/DocumentItem/DocumentItemEditorPage.vue')
              },
              {
                name: 'documentitem_translations',
                path: ':documentitemid/translations',
                component: () => import(/* webpackChunkName: "app-documentitem-translations" */ '@/components/documentItemTranslations/DocumentItemTranslationListing.vue')
              },
              {
                name: 'documentitem_translations_edit',
                path: ':documentitemid/translations/:documentitemtranslationid/edit',
                component: () => import(/* webpackChunkName: "app-documentitem-translations" */ '@/pages/DocumentItemTranslation/DocumentItemTranslationEditorPage.vue')
              },
              {
                name: 'documentitem_translations_add',
                path: ':documentitemid/translations/add',
                component: () => import(/* webpackChunkName: "app-documentitem-translations" */ '@/components/documentItemTranslations/forms/DocumentItemTranslationsForm.vue')
              }
            ]
          }
        ]
      },
      {
        name: 'definitions_landing',
        path: 'definitions',
        component: () => import( /* webpackChunkName: "app-definitions" */ '@/pages/Definition/DefinitionsLanding.vue' ),
        meta: { icon: 'mdi-book-open-page-variant', authorize: [] /*[Role.Admin]*/ }
      },
      {
        name: 'definition_home',
        path: 'definition/:definitionid',
        component: () => import(/* webpackChunkName: "app-definitions" */ '@/pages/Definition/DefinitionsPage.vue')
      },
      {
        name: 'persons_landing',
        path: 'persons',
        component: () => import( /* webpackChunkName: "app-persons" */ '@/pages/Person/PersonsLanding.vue' ),
        meta: { icon: 'mdi-account', authorize: [] /*[Role.Admin]*/ }
      },
      {
        name: 'person_home',
        path: 'person/:personid',
        component: () => import(/* webpackChunkName: "app-persons" */ '@/pages/Person/PersonsPage.vue')
      },
      {
        name: 'terms_landing',
        path: 'terms',
        component: () => import( /* webpackChunkName: "app-terms" */ '@/pages/Term/TermsLanding.vue' ),
        meta: { icon: 'mdi-file-document-box', authorize: [] /*[Role.Admin]*/ }
      },
      {
        name: 'term_home',
        path: 'term/:termid',
        component: () => import(/* webpackChunkName: "app-terms" */ '@/pages/Term/TermsPage.vue')
      },
      {
        name: "term_edit",
        path: "term/:termId/edit",
        component: () => import(/* webpackChunkName: "app-terms" */ '@/components/terms/TermEditor.vue'),
        props: true,
      },
      {
        path: 'search',
        name: 'search',
        component: () => import( /* webpackChunkName: "app-search" */ '@/pages/Search/SearchPage.vue' )
      }
    ]
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/Error/ErrorLayout.vue'),
    children: [{
      path: '',
      name: 'error',
      component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue')
    }]
  }
]

const { loginPath } = configs.settings

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition)
        }, 10)
      })
    } 
    if ( to.hash ) {
      return { selector: to.hash }
    }
    
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta
  const currentUser = store.state.account.accessToken
  const publicPages = [loginPath, '/auth/register']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = store.getters['account/loggedIn']

  if (authRequired && (!loggedIn || !currentUser)) {
    store.commit('account/RETURN_URL', to.fullPath)

    return next({
      path: loginPath,
      query: { returnUrl: to.fullPath }
    })
  }

  if (authorize) {
    if (!currentUser) {
      console.log('not logged in path ' + currentUser)

      // not logged in so redirect to login page with the return url
      return next({ path: loginPath, query: { returnUrl: to.path } })
    }

    // check if route is restricted by role
    // if (authorize.length && !authorize.includes(currentUser.role)) {
    if (authorize.length) {
      // role not authorised so redirect to home page
      console.error(`No perms to view this path: ${to}`)
      
      return next({ path: '/' })
    }
  }

  return next()
})

/**
 * After each route update
router.afterEach((to, from) => {
})
 */

export default router
